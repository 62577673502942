.image {
  display: block;
  width: 100%;
}

.text {
  font-size: 35px;
  color: black;
}

.sub-heading {
  font-size: 40px;
  color: black;
}

.heading {
  font-size: 80px;
  color: black;
  background-color: #c3b091;
  z-index: 1;
  position: relative;
  margin: 65px 430px 0 455px;
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.background {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.send-mail {
  font-size: 40px;
  padding: 40px;
  margin-bottom: 20px;
  border-radius: 10px;
  font-weight: 800px;
}