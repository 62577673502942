.content-page {
  margin-bottom: 80px;
}

.test-listing {
  text-align: center;
  font-size: 35px;
  color: #c3b091;
}

.test-heading {
  font-size: 40px;
  color: #c3b091;
  font-weight: 600;
}