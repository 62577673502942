@font-face {
  font-family: IstokWeb;
  src: url('./fonts/IstokWeb-Regular.ttf') format('truetype');
  font-weight: normal;
}

*{
  font-family: IstokWeb;
}

body {
  margin: 0;
  font-family: IstokWeb;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: IstokWeb;
}