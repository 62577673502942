.content-page {
  margin-bottom: 80px;
}

.buildung-listing {
  font-size: 35px;
  color: #c3b091;
  display: flex;
  width: 510px;
  align-self: center;
}

.buildung-heading {
  font-size: 40px;
  color: #c3b091;
  font-weight: 600;
}

.buildung-headline {
  width: 70%;
  align-self: center;
}