.content-page {
  margin-bottom: 80px;
}

.text-section {
  align-self: center;
}

.job-text {
  font-size: 35px;
  color: #c3b091;
  display: block;
}

.job-heading {
  font-size: 40px;
  color: #c3b091;
  font-weight: 600;
}