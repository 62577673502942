.content-page {
  margin-bottom: 80px;
}

.fire-listing {
  font-size: 35px;
  color: #c3b091;
  display: flex;
  align-self: flex-end;
  width: 1200px;
}

.fire-heading {
  font-size: 40px;
  color: #c3b091;
  font-weight: 600;
}