.footer-bar {
  width: 100%;
  height: 10rem;
  background-color: hwb(0 26% 74%);

  position: sticky;
  top: 0px;
  z-index: 1;

  box-shadow: 0px 6px 3px hwb(0 20% 83%);
}

.footer-link {
  padding: 1rem;
  font-size: medium;
}