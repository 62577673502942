.agb-content {
  margin-bottom: 80px;
  text-align: left;
}

.text-section {
  width: 80rem;
}

.agb-text {
  text-align: left;
  font-size: 25px;
  color: #c3b091;
}

.agb-heading {
  font-size: 40px;
  color: #c3b091;
  font-weight: 600;
}