.menu-bar {
  width: 100%;
  height: 10rem;
  background-color: hwb(0 26% 74%);

  position: sticky;
  top: 0px;
  z-index: 3;

  box-shadow: 0px 6px 3px hwb(0 20% 83%);
}

.logo-button {
  background: none;
  border: none;
}

.logo {
  padding-top: 1rem;
  height: 10rem;
  pointer-events: none;
}