.content-page {
  margin-bottom: 80px;
}

.contact-text {
  font-size: 35px;
  color: #c3b091;
  margin-top: 5px;
  margin-bottom: 5px;
}

.contact-mail {
  font-size: 40px;
  color: #c3b091;
}